import * as tslib_1 from "tslib";
import { Vue, Component, Prop, PropSync } from 'vue-property-decorator';
import Calendar from 'vue-mobile-calendar';
let DatePicker = class DatePicker extends Vue {
    /**
     * 时间发生改变
     * @param {date} 选中的时间值
     */
    change(date) {
        this.$emit('dateChange', date.map((item) => item.$d));
    }
};
tslib_1.__decorate([
    Prop({ type: String, default: 'single' })
], DatePicker.prototype, "mode", void 0);
tslib_1.__decorate([
    Prop({ type: [Date, Number, Array, String], default: '' })
], DatePicker.prototype, "defaultDate", void 0);
tslib_1.__decorate([
    Prop({ type: [Date, Number, Array, String], default: '' })
], DatePicker.prototype, "maxDate", void 0);
tslib_1.__decorate([
    Prop({ type: [Date, Number, Array, String], default: '' })
], DatePicker.prototype, "minDate", void 0);
tslib_1.__decorate([
    PropSync('show', { type: Boolean })
], DatePicker.prototype, "isShowDrawer", void 0);
DatePicker = tslib_1.__decorate([
    Component({
        components: {
            datePicker: Calendar.calendar,
        },
    })
], DatePicker);
export default DatePicker;
